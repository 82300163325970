import React, { Component } from "react";

export default class Legal extends Component {
	render() {
		return (
			<>
				<div class="container dark-text text-center mt-5">
					<h1 class="bold mb-4">Stiați că...</h1>
					<p class="ml-auto mr-auto font-medium">
						<b>Conform Legii nr. 136/1995</b> privind asigurările și reasigurările în România sau{" "}
						<b>Ordonanței de urgență nr. 54/2016</b> sau
						<b>Legii nr. 132/2017</b> privind asigurarea obligatorie de răspundere civilă auto pentru prejudicii produse
						terțelor persoane prin accidente de vehicule și de tramvaie sau <b>Legea nr. 32/2000</b> privind activitatea
						și supravegherea intermediarilor în asigurări și reasigurări,
						<b>
							ASIGURATORUL de RĂSPUNDERE CIVILĂ AUTO se obligă să plătească o DESPĂGUBIRE pentru prejudiciul creat de
							asigurat față de terțele persoane păgubite (persoanele vătămate corporal sau decedate în urma accidentelor
							rutiere).
						</b>
					</p>
					<div class="text-separator"></div>
					<p class="ml-auto mr-auto font-medium">
						Șoferii de autovehicule sunt obligați de lege să inchieie polițe de asigurare R.C.A. la mașini, în cazul în
						care produc un accident, polița de asigurare să plătească paguba produsă de șoferul vinovat
					</p>
					<p class="ml-auto mr-auto font-medium">
						Polițele de asigurare R.C.A. ale mașinilor vinovate sunt obligate să plătească atât
						<b> pagubele materiale</b> produse (costul cu reparația mașinii) dar și
						<b> despăgubiri pentru victimele</b> accidentelor rutiere (pietoni, pasageri, motociliști, bicicliști,
						șoferi, căruțași), persoane care au suferit <b> vătămări corporale</b> în urma accidentelor și care sunt
						nevinovați
					</p>
					<div class="text-separator"></div>
					<p class="ml-auto mr-auto font-medium">
						Limita de despăgubire pentru <b> pagube materiale</b> este de
						<b> 1.220.000 euro</b>. <br />
						Limita de despăgubire pentru <b> vătămări corporale</b> și deces este de
						<b> 6.070.000 euro.</b>
						<br />
						<b> Doar 20-30%</b> dintre victimele din accidente rutiere
						<b> își cunosc drepturile</b> și acționează să-și revendice despăgubirile
					</p>
				</div>

				<div class="container dark-text text-center bg-clear p-4 rounded-borders mt-5 mb-5">
					<h4 class="bold">
						Foarte multe victime nu acționează pentru că nu își cunosc drepturile, nu cunosc legislația, nu-și permit un
						avocat sau sunt informați eronat de diverse persoane fără pregătire și aleg să nu facă demersuri pentru a-și
						obține despăgubirea.
					</h4>
					<h2>Află dacă poți fi despăgubit...</h2>
					<h2>Contactează-ne</h2>
					<div class="row ml-auto mr-auto mt-4 flex-nowrap">
						<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
							<a href="tel:0314190560">
								<i class="fas fa-mobile icon-separator"></i>
							</a>
						</div>
						<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
							<a href="mailto:birou@adevar.org">
								<i class="fas fa-envelope icon-separator"></i>
							</a>
						</div>
						<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
							<a href="https://wa.me/0763686028/">
								<i class="fab fa-whatsapp icon-separator"></i>
							</a>
						</div>
					</div>
				</div>

				<div class="container dark-text text-center mb-5">
					<p class="ml-auto mr-auto font-medium">
						Foarte multe victime nu acționează și se mulțumesc cu ideea că bine că au scăpat cu viață și că nu le
						trebuie nimic de la șoferul vinovat sau de la asigurator. Perioada în care o victimă își poate revendica
						despăgubirea <b> expiră la 3 ani</b> de la data accidentului, în caz de deces acest termen poate urca
						<b> până la 5 ani.</b>
					</p>

					<div class="text-separator"></div>

					<p class="ml-auto mr-auto font-medium">
						Victimele accidentelor rutiere
						<b> pot obține despăgubiri chiar dacă șoferii vinovați sunt rude sau prieteni </b>
						cu victimele și aceștia <b> nu își revendică despăgubirea </b>
						pentru că consideră că îi fac rău șoferului.
					</p>
					<p class="ml-auto mr-auto font-medium">
						Foarte <b> multe victime </b> aleg sa
						<b> primească sume derizorii de la șoferii vinovați </b>
						pentru a renunța la drepturile lor civile si penale.
					</p>

					<p class="ml-auto mr-auto font-medium">
						In România au loc <b> mii de accidente rutiere </b> in urma cărora
						<b> foarte multe persoane iși pierd viața sau suferă vătămări </b>
						corporale grave iar in prezent <b> zeci de mii de victime </b> pot obtine despăgubiri dar
						<b> nu știu că au acest drept </b> și <b> nu stiu cum.</b>
					</p>
				</div>
			</>
		);
	}
}
