import React, { Component } from "react";
import default_img from "../assets/unnamed.png";
import "../css/team.css";
export default class Team extends Component {
	render() {
		return (
			<>
				<div className="container dark-text text-center mt-5 font-medium">
					<h1>Echipa Noastră</h1>
					<p className="ml-auto mr-auto">
						Echipa noastră este formată din avocați și consilieri juridici cu o vastă experiență în asigurări, lucrăm în
						domeniul asigurărilor de mai bine de 10 ani, o parte din colegii noștri au lucrat în trecut la diferite
						companii de asigurări din România, cunoaștem foarte bine politică de soluționare a daunelor de către
						asiguratori.
					</p>
				</div>

			</>
		);
	}
}
