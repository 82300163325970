import React, { Component } from "react";
import main_campain1 from "../assets/main_campain/main_campain1.jpeg";
import main_campain3 from "../assets/main_campain/main_campain3.jpeg";
import main_campain4 from "../assets/main_campain/main_campain4.jpeg";
import main_campain6 from "../assets/main_campain/main_campain6.jpeg";
export default class MainCampain extends Component {
	render() {
		return (
			<>
				<div class="container dark-text text-center mt-5 font-medium">
					<h1>Campanii Umanitare</h1>
					<p class="ml-auto mr-auto">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque aspernatur dicta ipsam repellendus nemo ab
						modi officiis natus esse quaerat.
					</p>
				</div>

				<div class="container mb-5">
					<div class="row">
						<div class="col-12">
							<div class="row">
								<a
									href={main_campain1}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={main_campain1} class="img-fluid card" alt="Poza campanie"></img>
								</a>
								<a
									href={main_campain6}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={main_campain6} class="img-fluid card" alt="Poza campanie"></img>
								</a>
								<a
									href={main_campain3}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={main_campain3} class="img-fluid card" alt="Poza campanie"></img>
								</a>
								<a
									href={main_campain4}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={main_campain4} class="img-fluid card" alt="Poza campanie"></img>
								</a>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
