import React, { Component } from "react";
import img1 from "../assets/vatamare-stock.jpg";
import img2 from "../assets/compesatie-stock.jpg";
export default class InfoVatamare extends Component {
	render() {
		return (
			<>
				<div class="container dark-text-2 mt-5">
					<div class="row justify-content-center">
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div class="font-medium">
								<h2 class="text-center mb-4 bold">Accident rutier soldat cu vătămare corporală</h2>
								<div class="injury-img">
									<img src={img1} alt="Vatamare accident rutier"></img>
								</div>
								<p>
									Sunteți victima unui accident rutier soldat cu vătămare corporală?
									<br />
									Aveți dreptul la despăgubiri dacă:
								</p>
								<ul class="spaced-list">
									<li>Accidentul rutier a fost declarat la poliție</li>

									<li>Ați suferit vătămări corporale și acestea au fost consemnate în documentele medicale</li>

									<li>
										Sunteți nevinovat de producerea accidentului sau vinovăția nu vă aparține în totalitate (culpă
										comună)
									</li>

									<li>
										Nu v-ați împăcat cu șoferul vinovat (prin împăcare se înțelege dacă șoferul vinovat a despăgubit el
										victima sau dacă victima declară la poliție că nu are nicio pretenție de orice natură de la vinovat)
									</li>
								</ul>

								<p class="mt-4 mb-4">
									Victimele au două drepturi, unul de natură penală (prin care șoferul vinovat va fi pedepsit de lege
									pentru faptă de vătămare corporală din culpă, dacă victima depășește 90 de zile de îngrijiri medicale
									sau dacă victima a rămas cu infirmitate sau un prejudiciu estetic grav și permanent ori i-a fost pusă
									viață în primejdie, consemnate în certificatul sau raportul medico-legal) și unul de natură civilă
									(prin care victimă poate obține despăgubiri)
								</p>
								<p>
									Victimele pot obține despăgubiri de la asigurări fără să depună plângere penală împotriva șoferului
									vinovat:
								</p>
								<ul class="spaced-list">
									<li>
										Se întâmplă de multe ori ca victimele să nu facă demersuri deoarece vinovați de producerea
										accidentelor sunt rude sau prieteni, victimele consideră că îi fac rău șoferului dacă fac demersuri,
										ESTE TOTAL FALS!
									</li>

									<li>
										Victimele pot declara la poliție că nu doresc să formuleze plângere penală împotriva șoferului, dar
										își rezervă dreptul de a face demersuri împotriva poliței de asigurare R.C.A a mașinii vinovate de
										producerea accidentului în vederea obținerii despăgubirilor legale
									</li>

									<li>
										Victimele trebuie să înțeleagă că de aceea plătim polițele obligatorii R.C.A. la mașini ca să
										plătească/repare pagubele produse de mașina vinovată, nu șoferii vinovați plătesc pentru pagubele
										produse
									</li>
								</ul>
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-medium mb-5">
							<div class="text-center mt-4 mb-4">
								<h4>Ce fel de despăgubiri pot obține victimele soldate cu</h4>
								<h2 class="bold">VĂTĂMARE CORPORALĂ?</h2>
							</div>

							<p>
								Victimele pot obține atât despăgubiri materiale, cât și despăgubiri morale de la societatea de asigurare
								(R.C.A.) a mașinii vinovate de producerea accidentului
							</p>

							<h2 class="para-title">Despăgubiri Materiale</h2>
							<ul class="spaced-list">
								<li>Despăgubirile materiale se referă la pierderile bănești ale victimelor, post accident</li>

								<li>
									Recuperarea tuturor cheltuielilor făcute și dovedite de victimă prin chitanțe, bonuri și facturi
									(medicamente, dispozitive medicale, servicii medicale, transport, tratamente medicale etc.)
								</li>

								<li>
									Recuperarea contravalorii obiectelor afectate/distruse de accident (vehicule, laptop-uri, telefoane
									mobile, tablete, bunuri etc.) consemnate de poliție în procesul verbal
								</li>

								<li>
									Recuperarea tuturor pierderilor bănești dovedite de victimă post accident (micșorarea salariului,
									contracte pierdute, bilete de avion, vacanțe programate, etc.), venituri nerealizate cauzate de
									accident (sporuri, bonusuri, prime, comisioane, bonuri de masă etc.).
								</li>

								<p class="bold">
									Conform legii asigurărilor, societățile de asigurări sunt obligate să
									<br />
									plătească toate cheltuielile realizate și dovedite de victime.
								</p>
							</ul>

							<h2 class="para-title">Despăgubiri Morale</h2>
							<ul class="spaced-list">
								<li>Despăgubirile morale se referă la suferința/trauma post accident</li>

								<li>
									Despăgubirile morale se probează cu martori și documente medicale eliberate de către medicii
									specialiști (bilete de extenare, scrisori medicale, concedii medicale, analize medicale) dar mai ales
									cu certificate sau rapoarte medico-legale eliberate de medicii legiști
								</li>

								<li>
									Victimele pot scoate la cerere un certificat medico-legal în termen de 30 de zile de la data
									accidentului, dacă situația medicală le permite
								</li>

								<li>
									Victimele pot solicita polițistului de caz să fie expertizat medico-legal în termen de 90 de zile de
									la data accidentului, dacă nu a reușit să se prezinte singure în primele 30 de zile.
								</li>
							</ul>
							<p class="bold">
								Suferința unei persoane nu are preț, societățile de asigurări nu pun accent pe suferința persoanei,
								interpretează în funcție de numărul de zile de îngrijiri medicale din certificatul medico-legal si oferă
								victimelor despăgubiri derizorii in raport cu prejudiciul suferit.
							</p>
						</div>
					</div>
				</div>

				<div class="compesation-img">
					<img src={img2} alt="Compensatie"></img>
				</div>
			</>
		);
	}
}
