import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./components/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Faq from "./components/Faq";
import InfoCampain from "./components/InfoCampain";
import InfoDeces from "./components/InfoDeces";
import InfoVatamare from "./components/InfoVatamare";
import Legal from "./components/Legal";
import MainCampain from "./components/MainCampain";
import Team from "./components/Team";
import Contact from "./components/Contact";
import About from "./components/About";
import BlogPost from "./components/BlogPost";
import ScrollToTop from "./components/ScrollToTop";
function App() {
	const scroll = () => {
		console.log("test");
		window.scrollTo(0, 0);
	};
	return (
		<div className="App">
			<BrowserRouter>
				<Nav></Nav>
				<ScrollToTop></ScrollToTop>
				<Switch>
					<Route path="/" component={Home} exact onUpdate={scroll}></Route>
					<Route path="/about" component={About} onUpdate={scroll}></Route>
					<Route path="/faq" component={Faq} onUpdate={scroll}></Route>
					<Route path="/campanii-informare" component={InfoCampain} onUpdate={scroll}></Route>
					<Route path="/deces" component={InfoDeces} onUpdate={scroll}></Route>
					<Route path="/vatamare" component={InfoVatamare} onUpdate={scroll}></Route>
					<Route path="/legal" component={Legal} onUpdate={scroll}></Route>
					<Route path="/campanii-umanitare" component={MainCampain} onUpdate={scroll}></Route>
					<Route path="/echipa" component={Team} onUpdate={scroll}></Route>
					<Route path="/contact" component={Contact} onUpdate={scroll}></Route>
					<Route path="/blog/post/:id" component={BlogPost} onUpdate={scroll}></Route>
				</Switch>
				<Footer></Footer>
			</BrowserRouter>
		</div>
	);
}

export default App;
