import React, { Component } from "react";
import ic1 from "../assets/info_campain/info_campain1.jpeg";
import ic3 from "../assets/info_campain/info_campain3.jpeg";
import ic4 from "../assets/info_campain/info_campain4.jpeg";
import ic5 from "../assets/info_campain/info_campain5.jpeg";
import ic6 from "../assets/info_campain/info_campain6.jpeg";
import ic7 from "../assets/info_campain/info_campain7.jpeg";
import ic8 from "../assets/info_campain/info_campain8.jpeg";
import ic9 from "../assets/info_campain/info_campain9.jpeg";

export default class InfoCampain extends Component {
	render() {
		return (
			<>
				<div class="container dark-text text-center mt-5 font-medium">
					<h1>Campanii de informare</h1>
					<p class="ml-auto mr-auto">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque aspernatur dicta ipsam repellendus nemo ab
						modi officiis natus esse quaerat.
					</p>
				</div>

				<div class="container mb-5">
					<div class="row">
						<div class="col-12">
							<div class="row">
								<a
									href={ic1}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic1} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic3}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic3} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic4}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic4} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic5}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic5} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic6}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic6} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic7}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic7} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic8}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic8} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
								<a
									href={ic9}
									data-toggle="lightbox"
									data-gallery="example-gallery"
									class="col-lg-3 col-md-4 col-6 my-3"
								>
									<img src={ic9} class="img-fluid card" alt="Poza din Campanie"></img>
								</a>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
