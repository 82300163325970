import React, { Component } from "react";

export default class Faq extends Component {
	render() {
		return (
			<div className="container mt-5">
				<h1 className="dark-text text-center bold">Întrebări Frecvente</h1>
				<div className="row dark-text mt-4 font-medium">
					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Sunt victima unui accident rutier, ce fel de
								despăgubiri pot obține?
							</h3>
							<p>
								Despăgubirile se împart în daune materiale și daune morale.
								<br />
								a) Pentru vătămare corporală:
								<br />
								- Cheltuielile prilejuite de accident (transport, tratament, spitalizare, recuperare, proteze, conform
								prescripţiilor medicale, probate cu documente justificative – bonuri, chitanțe, facturi);
								<br />
								– Veniturile pe care persoana nu le mai poate avea pe perioada spitalizării şi a concediului medical;
								<br />
								– Cheltuielile cu îngrijitorii pe perioada incapacităţii de muncă, dacă prin certificatul medical se
								recomandă acest lucru;
								<br />
								– Daunele morale
								<br />
								b) Pentru deces ca urmare a unui accident auto, se vor avea în vedere:
								<br />
								– Cheltuielile cu transportul persoanei decedate, cheltuielile de îmbălsămare, cheltuielile de
								înmormântare, inclusiv cele pentru piatră funerară, cheltuielile pentru îndeplinirea ritualurilor
								religioase;
								<br />– Daunele morale (vor fi acordate membrilor familiei: soţ/soţie (concubini), copii, părinţi,
								fraţi, bunici).
							</p>
						</div>
						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Dacă autoturismul care m-a vătămat are mașina
								înmatriculată în străinătate, mai pot primi despăgubiri?
							</h3>
							<p>
								În acest caz, despăgubirile vor fi plătite de către asigurator prin Biroul Asiguratorilor de
								Autovehicule din România(B.A.A.R.).
							</p>
						</div>

						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Cine este îndreptățit să primească despăgubiri?
							</h3>
							<p>
								a) Persoana vătămată; <br />
								b) În cazul decesului: soţ/soţie (concubini), copii, părinţi, fraţi, bunici. <br />
							</p>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>La cât timp după accident pot contacta Adevar?
							</h3>
							<p>
								Cu cât mai repede, cu atât mai bine. Din experienţă vă putem spune că unele aspecte sunt trecute cu
								vederea, multe documente sunt pierdute sau nu sunt solicitate (în special chitanţe şi facturi) sau se
								fac înţelegeri cu vinovaţii accidentelor în detrimentul dumneavoastră, partea vătămată.
							</p>
						</div>

						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Cât mă costă serviciile Asociaţiei Adevar?
							</h3>
							<p>
								Asociaţia percepe un procent din despăgubire, la sfârşit, atunci când societatea de asigurări va plăti
								despăgubirile. Până atunci, consultanţa noastră, reprezentarea avocaţilor noştri, a expertilor, opiniile
								medicilor etc., nu vă costă nimic.
							</p>
						</div>

						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Dacă autoturismul care m-a vătămat nu are
								asigurare obligatorie de răspundere civilă auto (RCA), mai pot primi despăgubiri?
							</h3>
							<p>
								În acest caz, despăgubirile vor fi plătite de către Fondul de Protecție a Victimelor Străzii (F.P.V.S.).
							</p>
						</div>

						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Conducătorul auto va avea de suferit în cazul
								solicitării unei despăgubiri?
							</h3>
							<p>
								Nu, conducătorul auto nu va achita despăgubirile. Societatea de asigurare despăgubește pentru
								prejudiciile materiale și morale produse părților vătămate în urma accidentului rutier.
							</p>
						</div>

						<div className="question">
							<h3 className="bold">
								<i className="far fa-question-circle mr-2 green-ico"></i>Dacă mă împac cu vinovatul, mai plătește
								societatea de asigurări despăgubiri?
							</h3>
							<p>
								Asiguratorul NU mai plătește despăgubiri dacă părțile se împacă, mai mult decât atât, spitalul, centrul
								de recuperare medicală vă vor solicita să plătiți serviciile medicale prestate, chiar dacă sunteți
								asigurat medical.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
