import React, { Component } from "react";
import img1 from "../assets/deces-stock.jpeg";
import img2 from "../assets/hugging-stock.jpg";
export default class InfoDeces extends Component {
	render() {
		return (
			<>
				<h2 class="text-center mt-4 mb-4 bold dark-text">Accident rutier soldat cu deces</h2>
				<div class="deces-img">
					<img src={img1} alt="Inmormantare"></img>
				</div>
				<div class="container dark-text-2 mt-5 ">
					<div class="row justify-content-center">
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div class="font-medium">
								<p>
									{" "}
									Sunteți victima unui accident rutier soldat cu deces? O rudă apropiată a decedat în urmă unui accident
									rutier?
								</p>
								<p>
									Rudele persoanelor decedate în urma accidentelor rutiere sunt considerate victime/persoane
									îndreptățite la despăgubiri de la societatea de asigurare R.C.A. a mașinii vinovate de producerea
									accidentului
								</p>
								<br></br>
								<p>Aveți dreptul la despăgubiri dacă:</p>
								<ul class="spaced-list">
									<li>Accidentul rutier a fost declarat la poliție</li>

									<li>
										Defunctul este nevinovat de producerea accidentului sau vinovăția nu îi aparține în totalitate
										(culpă comună)
									</li>

									<li>
										Persoana a decedat la locul accidentului sau în spital datorită leziunilor suferite din accident
									</li>

									<li>
										Dovediți că sunteți rudă cu defunctul cu acte de stare civilă (buletin, certificat de naștere,
										certificat de căsătorie)
									</li>

									<li>
										Dețineți actele de deces ale defunctului (certificat de deces, certificat constatator al decesului)
									</li>
								</ul>

								<p class="mt-4">
									Persoanele îndreptățite la despăgubiri sunt:{" "}
									<span class="semi-imp">
										părinții, soțul/soția, concubinul/concubina, copiii, frații/surorile, bunicii și/sau
										nepoții/nepoatele
									</span>
								</p>
								<p class="mb-4">
									Victimele au două drepturi, unul de natură penală (prin care șoferul vinovat va fi pedepsit de legea
									penală pentru fapta de ucidere din culpă și va fi trimis în judecată) și unul de natură civilă (prin
									care victimele pot obține despăgubiri legale de la asigurarea R.C.A. a mașinii vinovate)
								</p>
								<p>
									Victimele pot obține despăgubiri de la asigurări fără să influențeze dosarul penal al șoferului
									vinovat, dacă asta își doresc rudele defunctului!
								</p>
								<ul class="spaced-list">
									<li>
										Se întâmplă de multe ori ca victimele să nu facă demersuri deoarece vinovați de producerea
										accidentelor sunt rude sau prieteni, victimele consideră că îi fac rău șoferului dacă fac demersuri,
										ESTE TOTAL FALS!!!
									</li>

									<li>
										Victimele pot declara la poliție că nu doresc să formuleze plângere penală împotrivă șoferului, dar
										își rezervă dreptul de a face demersuri împotrivă poliței de asigurare R.C.A a mașinii vinovate de
										producerea accidentului în vederea obținerii despăgubirilor legale
									</li>

									<li>
										Victimele trebuie să înțeleagă că de aceea plătim polițele obligatorii R.C.A. la mașini că să
										plătească/repare pagubele produse de mașină vinovată, nu șoferii vinovați plătesc pentru pagubele
										produse
									</li>
								</ul>
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 font-medium mb-5">
							<div class="text-center mt-4 mb-4">
								<h4>Ce fel de despăgubiri pot obține victimele soldate cu</h4>
								<h2 class="bold">DECES?</h2>
							</div>

							<p>
								Victimele pot obține atât despăgubiri materiale, cât și despăgubiri morale de la societatea de asigurare
								(R.C.A.) a mașinii vinovate de producerea accidentului
							</p>

							<h2 class="para-title">Despăgubiri Materiale</h2>
							<ul class="spaced-list">
								<li>Despăgubirile materiale se referă la pierderile bănești ale victimelor, post accident</li>

								<li>
									Recuperarea tuturor cheltuielilor făcute și dovedite de victimă prin chitanțe, bonuri și facturi
									(medicamente, dispozitive medicale, servicii medicale, transport, tratamente medicale etc.)
								</li>

								<li>
									Recuperarea contravalorii obiectelor afectate/distruse de accident (vehicule, laptop-uri, telefoane
									mobile, tablete, bunuri etc.) consemnate de poliție în procesul verbal
								</li>

								<li>
									Recuperarea tuturor pierderilor bănești dovedite de victimă post accident (contracte pierdute, bilete
									de avion, vacanțe programate, etc.), venituri nerealizate cauzate de accident (sporuri, bonusuri,
									prime, comisioane, bonuri de masă etc.)
								</li>

								<p class="bold">
									Conform legii asigurărilor, societățile de asigurări sunt obligate să<br></br>
									plătească toate cheltuielile realizate și dovedite de victime.
								</p>
							</ul>

							<h2 class="para-title">Despăgubiri Morale</h2>
							<ul class="spaced-list">
								<li>Despăgubirile morale se referă la suferința/trauma post accident</li>

								<li>
									Despăgubirile morale se probează cu martori, documente medicale, dovadă gradului de rudenie, vârsta
									defunctului, statutul social și economic al defunctului
								</li>
							</ul>
							<p class="bold">
								Suferința/viața unei persoane nu are preț, societățile de asigurări nu pun accent pe suferința
								persoanei, de cele mai multe ori acestea nu despăgubesc echitabil victimele și acordă victimelor
								despăgubiri derizorii în raport cu prejudiciile suferite.
							</p>
						</div>
					</div>
				</div>

				<div class="hugging-img">
					<img src={img2} alt="Imbratisare"></img>
				</div>
			</>
		);
	}
}
