import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Footer extends Component {
	render() {
		return (
			<>
				<footer className="page-footer font-small unique-color-dark">
					<div style={{ backgroundColor: "#43df5d" }}>
						<div className="container">
							<div className="row py-4 d-flex align-items-center">
								<div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
									<h6 className="mb-0 shadowed-text">Alătura-te nouă pe rețele noastre sociale!</h6>
								</div>

								<div className="col-md-6 col-lg-7 text-center text-md-right">
									<a className="fb-ic" href="https://www.facebook.com/asociatiaadevar">
										<i className="fab fa-facebook-f fa-2x white-text mr-4"></i>
									</a>

									<a className="inst-ic" href="https://www.instagram.com/asociatia_adevar/">
										<i className="fab fa-instagram fa-2x white-text mr-4"></i>
									</a>
									<a className="whats-ic" href="https://wa.me/0720099992/">
										<i className="fab fa-whatsapp fa-2x white-text mr-4"></i>
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="container text-center text-md-left mt-5">
						<div className="row mt-3">
							<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
								<h6 className="text-uppercase font-weight-bold">Adevar</h6>
								<hr className="bg-green mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }}></hr>
								<p>
									Compasiune, respect, seriozitate, profesionalism, corectitudine, transparentă, determinare și
									încredere
								</p>
							</div>

							<div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
								<h6 className="text-uppercase font-weight-bold">Scurtături</h6>
								<hr className="bg-green mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }}></hr>
								<p>
									<Link to="/">Acasă</Link>
								</p>
								<p>
									<Link to="/despre">Ce oferim victimelor?</Link>
								</p>
								<p>
									<Link to="/contact">Contact</Link>
								</p>
								<p>
									<Link to="/echipa">Echipa Noastră</Link>
								</p>
							</div>

							<div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
								<h6 className="text-uppercase font-weight-bold">Rețele Sociale</h6>
								<hr className="bg-green mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }}></hr>
								<p>
									<a href="https://www.facebook.com/asociatiaadevar">Facebook</a>
								</p>
								<p>
									<a href="https://www.instagram.com/asociatia_adevar/">Instagram</a>
								</p>
								<p>
									<a href="https://wa.me/0720099992/">WhatsApp</a>
								</p>
							</div>

							<div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
								<h6 className="text-uppercase font-weight-bold">Contact</h6>
								<hr className="bg-green mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }}></hr>
								<p>
									<i className="fas fa-home mr-3"></i>Decebal Tower, Strada Turturelelor 62, București 030882
								</p>
								<p>
									<i className="fas fa-envelope mr-3"></i> birou@adevar.org
								</p>
								<p>
									<i className="fas fa-phone mr-3"></i> 0763 686 028
								</p>
								<p>
									<i className="fas fa-phone mr-3"></i> 0374 047 151
								</p>
							</div>
						</div>
					</div>

					<div className="footer-copyright text-center py-3">
						Adevar © 2020-{new Date().getFullYear()} Written by
						<a href="https://frann.dev"> Frann</a>
					</div>
				</footer>
			</>
		);
	}
}
