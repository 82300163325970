import React, { Component } from "react";
import "../css/map.css";
export default class Contact extends Component {
	render() {
		return (
			<>
				<div className="container">
					<div className="row text-center">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<div className="container dark-text font-medium mt-5">
								<h1 className="bold mb-2 dark-text">Consultanta gratuită</h1>
								<p className="mb-4">
									Dacă dumneavoastră sau o persoană dragă a suferit un accident rutier, ne puteți apela pentru o
									Consultanta gratuită!
								</p>
								<a className="a-button" href="tel:0763686028">
									Consultanta gratuită
								</a>
							</div>

							<div className="container dark-text font-medium mt-5">
								<h1 className="bold mb-2">Trimite-ne un E-Mail!</h1>
								<p>
									Ne puteți trimite un email la adresa{" "}
									<a className="link" href="mailto:birou@adevar.org">
										birou@adevar.org
									</a>
									<br />
									sau puteți completa formularul de mai jos.
								</p>
							</div>

							<div id="contact-cnt" className="container mt-max">
								<form action="/mail.php" method="POST" className="mt-5">
									<div className="form-row">
										<div className="form-group col-md-6">
											<input name="name" type="text" className="form-control" id="inputNume4" placeholder="Nume" />
										</div>
										<div className="form-group col-md-6">
											<input name="surname" type="text" className="form-control" id="inputPrenume4" placeholder="Prenume" />
										</div>
									</div>

									<div className="form-row">
										<div className="form-group col-md-6">
											<input name="email" type="email" className="form-control" id="inputEmail4" placeholder="Email" />
										</div>
										<div className="form-group col-md-4">
											<input name="phone" type="phone" className="form-control" id="inputPhone4" placeholder="Număr de Telefon" />
										</div>
									</div>
									<div className="form-row">
										<div className="form-group col-md-12">
											<textarea name="message" className="form-control" rows="5" id="mesaj" placeholder="Mesajul tău"></textarea>
										</div>
									</div>
									<div className="container text-center">
										<button type="submit" value="Submit" className="a-button mt-4 mb-5">
											Trimite
										</button>
									</div>
								</form>
							</div>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-5">
							<h1 className="bold dark-text mb-2">Adresa</h1>
							<p className="font-medium dark-text">
								De asemenea ne puteți găsi la biroul nostru! Decebal Tower, Strada Turturelelor 62, București 030882
							</p>

							<p className="font-medium dark-text">Apasa aici iar GPS-ul te va trimite la noi</p>
							<a
								className="a-button mb-2 mt-2"
								href="https://www.google.com/maps/dir/?api=1&destination=Decebal+Tower"
							>
								Directii
							</a>
							<div className="mapouter ml-auto mr-auto mt-5">
								<div className="gmap_canvas">
									<iframe
										title="Google Maps"
										width="500px"
										height="500px"
										id="gmap_canvas"
										src="https://maps.google.com/maps?q=Decebal%20Tower&t=&z=15&ie=UTF8&iwloc=&output=embed"
										frameborder="0"
										scrolling="no"
										marginheight="0"
										marginwidth="0"
									></iframe>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
