import React, { Component } from "react";
import Slider from "react-slick";
import "../css/sliding_logos.css";
import asig1 from "../assets/asigurari/allianz_tiriac.png";
import asig2 from "../assets/asigurari/Asirom-logo.jpg";
import asig4 from "../assets/asigurari/euroins.png";
import asig5 from "../assets/asigurari/grawe.jpg";
import asig6 from "../assets/asigurari/logo_groupama_asigurari_0.png";
import asig7 from "../assets/asigurari/OMNIASIG-logo.jpg";
import asig8 from "../assets/asigurari/uniqa.jpg";
import accident from "../assets/accident.jpg";
import logo from "../assets/logo.svg";
import BlogComponent from "./BlogComponent";
import { Link } from "react-router-dom";
export default class Home extends Component {
	render() {
		const settings = {
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1000,
			arrows: false,
			dots: false,
			pauseOnHover: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 520,
					settings: {
						slidesToShow: 2,
					},
				},
			],
		};
		return (
			<>
				<div id="top-image" className="container-fluid p-5">
					<section className="row no-gutter align-items-center">
						<div className="col-lg-12 text-center pt-5 pb-5 align-items-center">
							<h1 className="ml2 title w-100 text-white">ADEVAR</h1>
							<p className="w-100 subtitle text-white">Ajutăm în momentele grele</p>
						</div>
					</section>
				</div>
				<div className="d-flex banner">
					<div className="mt-2">{/* <h5 className="ml13"></h5> */}</div>
					<div className="free-cons-cont">
						<a className="d-inline free-cons dark-text" href="tel:0720099992" style={{ color: "inhherit" }}>
							<i className="fas fa-check mr-2"></i>Consultanță gratuita
						</a>
					</div>
				</div>

				<div className="container dark-text mt-5 text-center">
					<h1 className="bold">Asociația ADEVAR</h1>
					<p className="text-important mt-4 ml-auto mr-auto">
						Oferim <b>gratuit</b> servicii de consultanță și asistență de specialitate în domeniul <b>despăgubirilor</b>{" "}
						pentru <b>victime</b> din <b>accidente rutiere</b> soldate cu
						<b> vătămare corporală</b> sau cu <b> deces </b>.
					</p>
				</div>

				<div className="container mt-5">
					<div className="row mt-5 dark-text flex-column-reverse flex-md-row">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<img src={accident} className="img-fluid shadowed-img" alt="accident rutier"></img>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-2 text-center">
							<h1 className="bold">Misiunea noastră</h1>
							<p className="text-important">
								Informarea, consilierea, sprijinirea, reprezentarea și apărarea drepturilor victimelor din accidente
								rutiere, atât din România, cât și din Europa, în relația cu instituțiile abilitate, astfel încât să
								obținem pentru victime despăgubiri morale și materiale corecte și echitabile de la societățile de
								asigurare R.C.A responsabile de plată.
							</p>
						</div>
					</div>
				</div>

				<div className="container-fluid mt-5 bg-clear dark-text text-center p-5">
					<h2>Află cum poți fi despăgubit!</h2>
					<h2>Informează–te!</h2>
					<h2>Obține despăgubirea cuvenită!</h2>
					<h1 className="bold">Te ajutăm cu drag, pentru asta existăm!</h1>
					<img className="mt-5" src={logo} width="256px" height="256px" alt="Logo Adevar"></img>
					<div className="my-5">
						<h3>Sună la <a href="tel:0374047151" id="home-phone">0374 047 151</a> pentru consultare!</h3>
					</div>
					<div className="container mt-5">
						<div className="row">
							<div className="col">
								<Link className="a-button" to="/contact">
									Contact
								</Link>
							</div>
							<div className="col">
								<Link className="a-button" to="/faq">
									Intrebări
								</Link>
							</div>
						</div>
					</div>
				</div>

				<Slider {...settings} className="customer-logos">
					<div>
						<img src={asig1} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig2} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig4} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig5} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig6} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig7} alt="Asigurare RCA"></img>
					</div>
					<div>
						<img src={asig8} alt="Asigurare RCA"></img>
					</div>
				</Slider>

				<div className="container dark-text mt-5 mb-4 text-center">
					<h2 className="bold mb-4">Cât durează demersurile pentru victime?</h2>

					<a className="bold font-big mb-4 color-green" href="info_vatamare.html">
						Vătămare Corporală
					</a>
					<div className="row mt-4">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<h3 className="bold">Calea amiabilă</h3>
							<i className="fas fa-handshake icon-separator"></i>
							<p className="bold font-medium">Durată: 2 - 3 Luni</p>

							<p className="mt-4">
								Durata este cu titlu orientativ în funcție de particularitățile fiecărui caz (culpa victimei, starea
								medicală a victimei, disponibilitatea polițiștilor, asigurator, nevoile financiare ale victimelor,
								restricții, etc.)
							</p>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<h3 className="bold">Instanța judecătorească</h3>
							<i className="fas fa-gavel icon-separator"></i>
							<p className="bold font-medium">Durată: 12 - 18 Luni</p>

							<p className="mt-4">
								Durata este cu titlu orientativ în funcție de particularitățile fiecărui caz (culpa victimei, starea
								medicală a victimei, disponibilitatea polițiștilor/procurorilor, asigurator, nevoile financiare ale
								victimelor, restricții, etc.)
							</p>
						</div>
					</div>

					<a className="bold font-big mb-4 color-green" href="info_deces.html">
						Deces
					</a>
					<div className="row mt-4">
						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
						<h3 className="bold">Calea amiabilă</h3>
							<i className="fas fa-handshake icon-separator"></i>
							<p className="bold font-medium">Durată: 3 - 5 Luni</p>

							<p className="mt-4">
								Durata este cu titlu orientativ în funcție de particularitățile fiecărui caz (culpa victimei, raportul
								de necropsie, disponibilitatea polițiștilor, asigurator, nevoile financiare ale victimelor, restricții,
								etc.)
							</p>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
						<h3 className="bold">Instanța judecătorească</h3>
							<i className="fas fa-gavel icon-separator"></i>
							<p className="bold font-medium">Durată: 12 - 18 Luni</p>

							<p className="mt-4">
								Durata este cu titlu orientativ în funcție de particularitățile fiecărui caz (culpa victimei, raportul
								de necropsie, expertiza tehnică auto, disponibilitatea polițiștilor, asigurator, nevoile financiare ale
								victimelor, restricții, etc.)
							</p>
						</div>
					</div>
				</div>
				{/* <BlogComponent></BlogComponent> maybe readd later*/}
			</>
		);
	}
}
