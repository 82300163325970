import React, { Component } from "react";
import compassion from "../assets/compassion-stock.jpg";
import welcome from "../assets/welcome-stock.jfif";
export default class About extends Component {
	render() {
		return (
			<>
				<div class="container dark-text mt-5">
					<h1 class="bold text-center">Ce oferim victimelor?</h1>
					<div class="compassion-img">
						<img src={compassion} alt="compassion"></img>
					</div>
					<h2 class="bold text-center mt-4 mb-4">
						Compasiune, Empatie, Răbdare, Înțelegere, Respect, Importanță și mai ales RECUNOȘTINȚĂ
					</h2>

					<div class="ml-auto mr-auto font-medium list" style={{ maxWidth: "60ch", listStyle: "disc" }}>
						<li>
							Victimele accidentelor rutiere sunt persoane sensibile, sunt oameni care au trecut prin tragedii, noi îi
							tratăm cu multă compasiune și cu căldură
						</li>

						<li>
							Îi ascultăm cu multă răbdare și atenție, le înțelegem nevoile și le acordăm tot suportul pentru a se
							recupera medical și psihologic
						</li>

						<li>
							Tratăm victimele cu mult respect și importanță maximă, suntem conștienți că datorită dosarelor lor suntem
							recompensați și dacă victimele nu sunt mulțumite de colaborare, noi ne pierdem credibilitatea față de
							victime și nu vom mai primi recomandări de la aceștia
						</li>
						<li>
							Ținem foarte mult ca victimele care aleg să colaboreze cu noi să fie mulțumite și să ne recomande mai
							departe la alte persoane care trec prin situații similare lor, așa ne facem cunoscuți, așa ne câştigăm
							respectul și încrederea victimelor, în acest fel am devenit o companie de succes în domeniul
							despăgubirilor din asigurări
						</li>
					</div>
				</div>

				<div class="container dark-text">
					<h2 class="bold text-center mt-5 mb-5">Confort, Eficiența, Organizare, Profesionalism, Implicare Activă</h2>
					<div class="ml-auto mr-auto font-medium list" style={{ maxWidth: "60ch", listStyle: "disc" }}>
						<li>Noi ne ocupăm de tot, nu punem victima pe drumuri să completeze dosarul cu documentele necesare</li>

						<li>
							Singurul efort pe care îl depune victima este să se prezinte la medicină legală (în caz de vătămare),
							deoarece nu ne poate consulta pe noi medicul legist
						</li>

						<li>
							Lucrăm dosarele în echipă, fiecare membru al echipei are rolul ei stabilit, suntem foarte bine organizați
							și fiecare știe ce are de făcut
						</li>
						<li>
							Folosim timpul eficient, unul merge la poliție, altul face adrese, altul asistă victima la declarații,
							altul scrie cererea de despăgubire, altul merge la asigurări, altul sună la poliție, parchet și medicina
							legală, altul scrie cererea de chemare în judecată, altul asistă victima în instanță de judecată
						</li>

						<li>
							Acordăm importanță fiecărui dosar în parte, facem ședințe săptămânale pentru a discuta fiecare dosar
						</li>

						<li>
							Analizăm și evaluăm cu mare atenție toate amănuntele importante din fiecare dosar, pentru a scoate în
							evidență prejudiciul suferit de victimă;
						</li>

						<li>
							Fiecare membru din echipă se implică activ în dosar și își exprimă opinia juridică pentru a maximiza
							valoarea despăgubirilor
						</li>
					</div>
				</div>

				<div class="container dark-text font-medium text-center info-box mt-5">
					<h2 class="bold mt-4">Suntem aici să vă ajutăm</h2>
					<p class="ml-auto mr-auto">Apelați-ne pentru orice informație!</p>
					<div class="mt-5">
						<a class="a-button" href="tel:0763686028">
							0763 686 028
						</a>
					</div>
				</div>

				<div class="container dark-text mb-5">
					<h2 class="bold text-center mt-5 mb-5">Informare, Comunicare, Transparență, Promtitudine, Consultare</h2>
					<div class="ml-auto mr-auto font-medium list" style={{ maxWidth: "60ch", listStyle: "disc" }}>
						<li>
							Din momentul în care victimele au semnat contractul cu noi, li se prezintă toate etapele procedurale pe
							care le vom parcurge, li se aduce la cunoștință termenele prestabilite de soluționare și momentele cheie
							la care victimă va fi informată
						</li>

						<li>
							Pe tot parcursul demersurilor atât echipa juridică cât și victima sunt periodic în contact pentru ca
							dosarul să fie perfect întocmit, să nu se omită detalii și aspecte importante pentru maximizarea
							despăgubirilor
						</li>

						<li>
							Pentru noi este foarte importantă comunicarea cu victima pentru a cunoaște îndeaproape toate problemele cu
							care se confruntă/ prejudiciile create de accident (cum a fost afectată victima medical, profesional,
							psihologic, social, educațional etc.)
						</li>

						<li>La fiecare etapă parcursă în dosar, victima este informată cu progresul făcut</li>

						<li>
							Ori de câte ori ne contactează victimele, suntem obligați să le răspundem și să le oferim informațiile
							cerute și să le explicăm pe înțelesul acestora situațiile apărute în dosar
						</li>

						<li>
							Echipa noastră lucrează transparent, în momentul în care apar ofertele de despăgubiri, victimele sunt
							imediat contactate, informate și consiliate dacă aceste oferte sunt, sau nu, pe măsură nevoilor lor, le
							explicăm cu răbdare și pe înțelesul lor, cu argumente întemeiate, dacă se merită, sau nu, să acceptăm
							ofertele
						</li>

						<li>
							Noi ne consultăm cu victimele și nu luăm nici o decizie fără ca acestea să știe sau fără să primim acordul
							lor expres
						</li>

						<li>
							Compania are obligația să se asigure că despăgubirile pentru victimă sunt echitabile și îndestulătoare
							pentru a stinge prejudiciul creat de accident
						</li>

						<li>
							După ce companiile de asigurare ne emit ofertele de despăgubire, victimele vor fi informate, ne consultăm
							cu ele dacă acceptă sau nu
						</li>

						<li>
							Dacă victimele acceptă despăgubirile, acestea sunt chemate la birou, semnează acordul de tranzacție, ne
							oferă un cont bancar și așteaptă ca în termenul legal să primească despăgubirea în contul indicat de
							acestea
						</li>
					</div>
				</div>
				<div class="welcome-img">
					<img src={welcome} alt="welcome"></img>
				</div>
			</>
		);
	}
}
