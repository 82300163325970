import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/blog_post.css";
export default function BlogPost() {
	const [blogPostData, setBlogPostData] = useState("");

	// id for url routing
	let { id } = useParams();

	useEffect(() => {
		if (!blogPostData) {
			getBlogPosts();
		}
	});

	const getBlogPosts = async () => {
		const response = await axios.get("http://127.0.0.1:8000/blog/posts/");
		const data = await response.data;
		setBlogPostData(data);
	};

	return (
		<>
			<div className="container mt-5 mb-5">
				<h1 id="post-title" className="dark-text text-center">
					{blogPostData ? blogPostData[id].fields.title : "Loading"}
				</h1>
				<h2 id="subcontent" className="dark-text mt-4 mb-4">
					{blogPostData ? blogPostData[id].fields.sub_content : "Loading"}
				</h2>
				<div
					className="content"
					dangerouslySetInnerHTML={{ __html: blogPostData ? blogPostData[id].fields.content : "Loading" }}
				></div>
				<p className="muted">
					Scris in data de {blogPostData ? blogPostData[id].fields.created_on.split("T")[0] : "Loading"}
				</p>
			</div>
		</>
	);
}
