import React, { Component } from "react";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
export default class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleMenu() {
		this.setState({ menu: !this.state.menu });
	}

	render() {
		const show = this.state.menu ? "show" : "";

		return (
			<>
				<nav id="top" className="navbar navbar-expand-lg navbar-light bg-white fixed-top autohide">
					<Link className="navbar-brand" to="/">
						<img src={logo} width="130" height="120" className="d-inline-block align-top" alt="Logo Adevar" />
					</Link>

					<button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={"collapse navbar-collapse " + show} id="navbarNavDropdown">
						<ul className="navbar-nav">
							<li className="nav-item big">
								<Link className="nav-link" to="/">
									Acasă
								</Link>
							</li>
							<li className="nav-item big">
								<Link className="nav-link" to="/about">
									Ce oferim victimelor?
								</Link>
							</li>

							<li className="nav-item big dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="/#"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Informații Utile
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<Link className="dropdown-item" to="/vatamare">
										Vătămare Corporală
									</Link>
									<Link className="dropdown-item" to="/deces">
										Deces
									</Link>
									<div className="dropdown-divider"></div>
									<Link className="dropdown-item" to="/faq">
										Intrebări Frecvente
									</Link>
									<div className="dropdown-divider"></div>
									<Link className="dropdown-item" to="/legal">
										Stiați că?
									</Link>
								</div>
							</li>
							<li className="nav-item big">
								<Link className="nav-link" to="/echipa">
									Echipa ADEVAR
								</Link>
							</li>
							{/* <li className="nav-item big dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="/#"
									id="navbarDropdown2"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Activitatea noastră
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown2">
									<Link className="dropdown-item" to="/campanii-informare">
										Campanii de informare
									</Link>
									<div className="dropdown-divider"></div>
									<Link className="dropdown-item" to="/campanii-umanitare">
										Campanii umanitare
									</Link>
								</div>
							</li> */}
							{/* <li className="nav-item big">
								<a className="nav-link" href="/pages/partners.html">
									Parteneri
								</a>
							</li> */}
							<li className="nav-item big">
								<Link className="nav-link" to="/contact">
									Contact
								</Link>
							</li>
						</ul>
					</div>
				</nav>
			</>
		);
	}
}
